import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {NbAuthService} from './auth.service';
import {NbAccessChecker} from '../../security/services/access-checker.service';
import {NbTokenStorage} from './token/token-storage';
import {map, switchMap} from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: NbAuthService,
              private router: Router,
              private tokenStorage: NbTokenStorage,
              private accessChecker: NbAccessChecker) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.authService.isAuthenticated()
      .pipe(
        map(authenticated => {
          if (!authenticated) {
            this.tokenStorage.clear();
            this.router.navigate(['auth/login']);
          }
          return authenticated;
        }),
        switchMap(res => {
          return this.tokenStorage.loadState();
        }), map(res => {
          if (next.data.permission && next.data.resource) {
            this.accessChecker.isGranted(next.data.permission, next.data.resource).subscribe(value => {
              if (!value)
                this.router.navigate(['pages/403']);
            });
          }
          return true;
        }))
  }
}
