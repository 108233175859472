import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class Globals {


  public ATTRIBUTE_STATUS_CANCELLED: number = -1;
  public ATTRIBUTE_STATUS_ACTIVE: number = 0;
  public ATTRIBUTE_DATATYPE_STRING = 1;
  public ATTRIBUTE_DATATYPE_LONG = 2;
  public ATTRIBUTE_DATATYPE_TIMESTAMP = 3;
  public ATTRIBUTE_DATATYPE_BOOLEAN = 4;
  public SURVEY_MODE_CANCELLED: number = -1;
  public SURVEY_MODE_DRAFT: number = 0;
  public SURVEY_MODE_PUBLISHED: number = 1;
  public SURVEY_MODE_ARCHIVED: number = 2;
  public QUESTION_TYPE_UNDEFINED: number = -1;
  public QUESTION_TYPE_FREE: number = 1;
  public QUESTION_TYPE_MULTIPLE_CHOICE: number = 2;
  public QUESTION_TYPE_MULTIPLE_CHOICE_EXCLUSIVE: number = 3;

  public PROJECT_SECURITY_YES: number = 2;
  public PROJECT_SECURITY_YES_WITH_REQ: number = 3;
  public PROJECT_SECURITY_YES_NO_REQ: number = 4;
  public PROJECT_SECURITY_NO: number = 1;

  public PROJECT_STATUS_UNDEFINED: number = 0;
  public PROJECT_STATUS_COMPLETED: number = 1;
  public PROJECT_STATUS_CANCELLED: number = -1;
  public PROJECT_STATUS_VALIDATED: number = 2;

  public PROJECT_STATUS_MAP = new Map<number, string>([
    [this.PROJECT_STATUS_UNDEFINED, 'Non pervenuto'],
    [this.PROJECT_STATUS_COMPLETED, 'In lavorazione'],
    [this.PROJECT_STATUS_CANCELLED, 'Cancellato'],
    [this.PROJECT_STATUS_VALIDATED, 'Emesso'],
  ]);

  public OTE_STATUS_CANCELLED: number = -1;
  public OTE_STATUS_UNDEFINED: number = 0;
  public OTE_STATUS_DRAFT: number = 1;
  public OTE_STATUS_SUSPENDED: number = 2;
  public OTE_STATUS_POSTPONED: number = 3;
  public OTE_STATUS_DELIVERED: number = 4;
  public OTE_STATUS_UNSECURED: number = 5;
  public OTE_STATUS_MAP = new Map<number, string>([
    [this.OTE_STATUS_CANCELLED, 'Annullato'],
    [this.OTE_STATUS_UNDEFINED, 'Non pervenuto'],
    [this.OTE_STATUS_DRAFT, 'In lavorazione'],
    [this.OTE_STATUS_SUSPENDED, 'Sospeso'],
    [this.OTE_STATUS_POSTPONED, 'Rinviato'],
    [this.OTE_STATUS_DELIVERED, 'Emesso'],
    [this.OTE_STATUS_UNSECURED, 'Rilasciato senza sicurezza'],
  ]);
  public APP_STATUS_ACTIVE: number = 1;
  public APP_STATUS_CANCELLED: number = -2;
  public HTTP_NOT_ERROR_STATUS_ID: number = 0;
  public HTTP_ERROR_STATUS_ID: number = 1;
  public COMPANY_STATUS_ACTIVE: number = 1;
  public COMPANY_STATUS_NOT_ACTIVE: number = 0;
  public COMPANY_STATUS_BLOCKED: number = -1;
  public COMPANY_STATUS_CANCELLED: number = -2;
  public USER_STATUS_ACTIVE: number = 1;
  public USER_STATUS_NOT_ACTIVE: number = 0;
  public USER_STATUS_BLOCKED: number = -1;
  public USER_STATUS_CANCELLED: number = -2;
  public QUESTION_SECTIONS = new Map<number, string>([
    [1, ''],
  ]);
  public REASON_DISMISS_BIA = [
    'Implementazioni SW ed HW, attualmente non riconducibili ad informazioni, finalizzate alla progettazione, distribuzione, integrazione e amministrazione di soluzioni a supporto dei Servizi di business',
    'Estrazione, visualizzazione di dati che non saranno duplicati in nuovi archivi',
    'Supporto al Business',
    'Nessuna modifica a Dati di Quick Assessment & BIA precedente',
    'Richiesta di utilizzo VPN o altri canali sicuri',
    'Richieste di tipo documentale per utilizzare il DMS solo se prive di impatti sull erogazione di nuovi prodotti o servizi',
    'Utilizzo di dati fittizi o mascherati',
    'Mancante',
    'Utilizzo di dati fittizi o mascherati',
    'Supporto a gare non contenenti specifici requisiti di Sicurezza delle Informazioni',
  ];
  public ROLE_UNDEFINED_ID = 8;
  public ROLE_ADMIN_ID = 2;
  public ROLE_LIBRARY_MANAGER_ID = 3;
  public ROLE_PLATFORM_MANAGER_ID = 4;
  public ROLE_PROJECT_MANAGER_ID = 5;
  public ROLE_SECURITY_MANAGER_ID = 7;
  public ROLE_SECURITY_MANAGER: string = 'SECURITY_MANAGER';
  public ROLE_ROOT: string = 'ROOT';
  public ROLE_UNDEFINED: string = 'ROLE_UNDEFINED';
  public ROLE_PLATFORM_MANAGER: string = 'PLATFORM_MANAGER';
  public ROLE_PROJECT_MANAGER: string = 'PROJECT_MANAGER';
  public ROLE_LIBRARY_MANAGER: string = 'LIBRARY_MANAGER';
  public ROLE_ADMIN: string = 'ADMIN';
  public ROLE_IDS = new Map<string, number>([
    [this.ROLE_ROOT, 6],
    [this.ROLE_UNDEFINED, this.ROLE_UNDEFINED_ID],
    [this.ROLE_PLATFORM_MANAGER, this.ROLE_PLATFORM_MANAGER_ID],
    [this.ROLE_LIBRARY_MANAGER, this.ROLE_LIBRARY_MANAGER_ID],
    [this.ROLE_PROJECT_MANAGER, this.ROLE_PROJECT_MANAGER_ID],
    [this.ROLE_SECURITY_MANAGER, this.ROLE_SECURITY_MANAGER_ID],
    [this.ROLE_ADMIN, this.ROLE_ADMIN_ID],
  ]);
  public ROLE_IDS_KEYS = new Map<number, string>([
    [this.ROLE_UNDEFINED_ID, this.ROLE_UNDEFINED],
    [this.ROLE_PLATFORM_MANAGER_ID, this.ROLE_PLATFORM_MANAGER],
    [this.ROLE_LIBRARY_MANAGER_ID, this.ROLE_LIBRARY_MANAGER],
    [this.ROLE_PROJECT_MANAGER_ID, this.ROLE_PROJECT_MANAGER],
    [this.ROLE_SECURITY_MANAGER_ID, this.ROLE_SECURITY_MANAGER],
    [this.ROLE_ADMIN_ID, this.ROLE_ADMIN],
  ]);
  public ROLES = [
    {value: this.ROLE_UNDEFINED_ID, title: 'Non definito', key: this.ROLE_UNDEFINED},
    {value: this.ROLE_ADMIN_ID, title: 'SuperUser', key: this.ROLE_ADMIN},
    {value: this.ROLE_LIBRARY_MANAGER_ID, title: 'Amministratore della Libreria', key: this.ROLE_LIBRARY_MANAGER},
    {value: this.ROLE_PLATFORM_MANAGER_ID, title: 'Amministratore della Piattaforma', key: this.ROLE_PLATFORM_MANAGER},
    {value: this.ROLE_PROJECT_MANAGER_ID, title: 'Project Manager', key: this.ROLE_PROJECT_MANAGER},
    {
      value: this.ROLE_SECURITY_MANAGER_ID,
      title: 'Responsabile della Sicurezza Informatica',
      key: this.ROLE_SECURITY_MANAGER
    },
  ];
  public ROLE_CAN_CREATE_ROLE_IDS = new Map<string, number[]>([
    [this.ROLE_ADMIN, [this.ROLE_PLATFORM_MANAGER_ID, this.ROLE_LIBRARY_MANAGER_ID]],
    [this.ROLE_PLATFORM_MANAGER, [this.ROLE_SECURITY_MANAGER_ID, this.ROLE_PROJECT_MANAGER_ID]],
    [this.ROLE_LIBRARY_MANAGER, []],
    [this.ROLE_PROJECT_MANAGER, []],
    [this.ROLE_SECURITY_MANAGER, []],
    [this.ROLE_UNDEFINED, []],
  ]);
  public ROLE_CAN_CREATE_ROLES = new Map<string, any[]>([
    [this.ROLE_ADMIN,
      [
        {value: this.ROLE_PLATFORM_MANAGER_ID, title: 'Amministratore della Piattaforma'},
        {value: this.ROLE_LIBRARY_MANAGER_ID, title: 'Amministratore della Libreria'},
      ]
    ],
    [this.ROLE_PLATFORM_MANAGER,
      [
        {value: this.ROLE_SECURITY_MANAGER_ID, title: 'Responsabile della Sicurezza Informatica'},
        {value: this.ROLE_PROJECT_MANAGER_ID, title: 'Project Manager'},
      ]
    ],
    [this.ROLE_LIBRARY_MANAGER, []],
    [this.ROLE_PROJECT_MANAGER, []],
    [this.ROLE_SECURITY_MANAGER, []],
    [this.ROLE_UNDEFINED, []],
  ]);
  public MAX_ELEMENT_AUTOCOMPLETE: number = 5;

  public TEMPLATE_STATUS_NOT_ACTIVE: number = 0;
  public TEMPLATE_STATUS_ACTIVE: number = 1;

  public TEMPLATE_NOTIFICATION_TO_PM = 1; // lock
  public TEMPLATE_NOTIFICATION_TO_RS = 2; // lock
  public TEMPLATE_NOTIFICATION_EDIT_REQUIREMENT = 3;
  public TEMPLATE_PROJECT_CREATED = 4;
  public TEMPLATE_PROJECT_UPDATED = 5;
  public TEMPLATE_PROJECT_STATUS_CHANGED_TO_UNDEFINED = 6;
  public TEMPLATE_PROJECT_STATUS_CHANGED_TO_VALIDATED = 7;
  public TEMPLATE_PROJECT_STATUS_CHANGED_TO_VALIDATED_WITH_COMPLIANCE_DOMAINS = 8;
  public TEMPLATE_SCHEDULED = 9;

  public TEMPLATE_IDS_MAP = new Map<number, string>([
    [this.TEMPLATE_NOTIFICATION_TO_PM, 'Notifica al Project Manager'],
    [this.TEMPLATE_NOTIFICATION_TO_RS, 'Notifica al Responsabile Sicurezza'],
    [this.TEMPLATE_NOTIFICATION_EDIT_REQUIREMENT, 'Notifica Modifica Requisiti'],
    [this.TEMPLATE_PROJECT_CREATED, 'Notifica Progetto creato'],
    [this.TEMPLATE_PROJECT_UPDATED, 'Notifica Progetto modificato'],
    [this.TEMPLATE_PROJECT_STATUS_CHANGED_TO_UNDEFINED, 'Notifica cambio stato progetto a non pervenuto'],
    [this.TEMPLATE_PROJECT_STATUS_CHANGED_TO_VALIDATED, 'Notifica cambio stato progetto a validato'],
    [this.TEMPLATE_PROJECT_STATUS_CHANGED_TO_VALIDATED_WITH_COMPLIANCE_DOMAINS,
      'Notifica cambio stato progetto a validato con domini di compliance'],
    [this.TEMPLATE_SCHEDULED, 'Notifica schedulata'],
  ]);

  // public TEMPLATE_PROJECT_CREATED = 4;
  // public TEMPLATE_PROJECT_STATUS_CHANGE_TO_COMPLETED = 2;
  // public TEMPLATE_PROJECT_STATUS_CHANGE_TO_VALIDATED = 3;
  // public TEMPLATE_PROJECT_COMPLIANCE_PERIMETERS = 4;
  // public TEMPLATE_SCHEDULED = 5;
  // public TEMPLATE_PROJECT_IMPACT_THRESHOLD = 6;

  public TEMPLATE_FREQUENCY_MONTHLY = 4;
  public TEMPLATE_FREQUENCY_WEEKLY = 3;

  constructor(private translate: TranslateService) {

  }


}
