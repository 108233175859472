import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {throwIfAlreadyLoaded} from './module-import-guard';
import {DataModule} from './data/data.module';
import {AnalyticsService} from './utils/analytics.service';
import {NbPasswordAuthStrategy} from '../auth/strategies';
import {NbAuthJWTToken} from '../auth/services';
import {HttpResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {NbAuthModule} from '../auth/auth.module';

const socialLinks = [
  {
    url: '',
    target: '_blank',
    icon: 'socicon-github',
  },
  {
    url: '',
    target: '_blank',
    icon: 'socicon-facebook',
  },
  {
    url: '',
    target: '_blank',
    icon: 'socicon-twitter',
  },
];

export function getNbAuthJWTToken(module: string, res: HttpResponse<Object>) {
  return res.headers.get(environment.authHeader);
}

export const NB_CORE_PROVIDERS = [
  ...DataModule.forRoot().providers,
  ...NbAuthModule.forRoot({

    strategies: [
      NbPasswordAuthStrategy.setup({
        name: 'email',
        baseEndpoint: environment.apiBaseEndpoint,
        login: {
          endpoint: environment.authEndpoint + 'signin',
          method: 'POST',
          defaultErrors: ['Login/Email combination is not correct, please try again.'],
          redirect: {
            success: '/',
            failure: null,
            reset: '/auth/reset-password'
          },
        },
        logout: {
          endpoint: '',
          method: 'GET',
          redirect: {
            success: '/',
            failure: '/',
          },
          defaultErrors: ['Something went wrong, please try again.'],
          defaultMessages: ['You have been successfully logged out.'],
        },
        refreshToken: {
          endpoint: environment.authEndpoint + 'refresh',
          method: 'put',
          requireValidToken: false,
          redirect: {
            success: null,
            failure: null,
          },
          defaultErrors: ['Something went wrong, please try again.'],
          defaultMessages: ['Your token has been successfully refreshed.'],
        },
        resetPass: {
          endpoint: environment.authEndpoint + 'change_password',
          method: 'PUT',
          defaultErrors: ['Wrong password combination.'],
          redirect: {
            success: '/auth/login',
            failure: null,
          },
        },
        token: {
          class: NbAuthJWTToken,
          getter: getNbAuthJWTToken,
        },
      }),
    ],
    forms: {
      login: {
        redirectDelay: 500,
        socialLinks: socialLinks,
        rememberMe: false,
        showMessages: {
          success: true,
          error: true,
        },
      },
      validation: {
        password: {
          required: true,
          minLength: 1,
        },
        email: {
          required: true,
          minLength: 1,
        },
      },
    },
  }).providers,
  AnalyticsService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
