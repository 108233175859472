import {EventEmitter, Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ToasterEmitterService {

  toasterEmitter: EventEmitter<ToasterEvent> = new EventEmitter<ToasterEvent>();
  closeModalEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();


  constructor(private translate: TranslateService) {
  }

  public emitHttpError(error: HttpErrorResponse) {
    this.toasterEmitter.emit(new ToasterEvent('error', 'Errore', error.message));
    this.closeModalEmitter.emit(true);
  }

  public emit(toaster: ToasterEvent) {
    this.toasterEmitter.emit(toaster);
  }

  public emitHttpTranslated(res: any) {
    if (res.body['status'] !== undefined && res.body['status'].id > 0) {
      let status = res.body['status'];
      this.translate.get('RESPONSES.' + status.code)
        .subscribe((s: string) => {
          if (res.body['data'] !== null) {
            s = s + ' [' + res.body['data'] + ']';
          }
          this.toasterEmitter.emit(new ToasterEvent('error', 'Errore', s));
          this.closeModalEmitter.emit(true);
        }).unsubscribe();
    }
  }

}

export class ToasterEvent {
  constructor(public type?: string,
              public title?: string,
              public message?: any,
              public special?: any) {
  }
}
