export const environment= {
  production: false,
  apiBaseEndpoint: 'https://fnc2018.servehttp.com/api/',
  domain: '.' + window.location.hostname.split('.').slice(-2).join('.'),
  appName: 'otews',
  refreshPercentage: 0.5,
  tableItemPerPage: 20,
  tableMiniItemPerPage: 5,
  tableEnablePager: true,
  authCookie: '_keymaker_token',
  appCookie: '_keymaker_app',
  roleCookie: '_keymaker_roles',
  authHeader: 'X-Auth-Token',
  defaultLang: 'it',
  dateFormat: 'dd/MM/yyyy HH:mm:ss',
  simpleDateFormat: 'dd/MM/yyyy',
  changePasswordInterval: 7776000, // 3 mesi

  // SERVER DEV CONFIG
  authEndpoint: 'keymakerauthws/auth/',
  refreshTokenEndpoint: 'keymakerauthws/auth/refresh',
  companyEndpoint: 'keymakeruserws/company/',
  companyLevelEndpoint: 'keymakeruserws/companylevel/',
  userEndpoint:  'keymakeruserws/user/',
  roleEndpoint:  'keymakeruserws/role/',
  userImportEndpoint: 'keymakeruserws/user_import/',
  privilegeEndpoint:  'otews/privilege/',
  projectEndpoint: 'otews/project/',
  applicationEndpoint: 'otews/application/',
  packageEndpoint: 'otews/application_package/',
  managedDataEndpoint: 'otews/managed_data/',
  customManagedDataEndpoint: 'otews/custom_managed_data/',
  fileEndpoint: 'otews/file/',
  fileUserEndpoint: 'keymakeruserws/file/',
  complianceDomainEndpoint: 'otews/compliance_domain/',
  requirementEndpoint: 'otews/requirement/',
  attributeEndpoint: 'otews/attribute/',
  sectionEndpoint: 'otews/section/',
  syncEndpoint: 'otews/sync/',
  surveyEndpoint: 'otews/survey/',
  libraryEndpoint: 'otews/library/',
  questionEndpoint: 'otews/question/',
  responseEndpoint: 'otews/response/',
  reportEndpoint: 'otereportws/report/',
  dashboardEndpoint: 'otews/dashboard/',
  templateEndpoint: 'otews/template/',


};
