import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import 'rxjs-compat/add/observable/empty';
import 'rxjs-compat/add/observable/of';
import {FileSaverService} from 'ngx-filesaver';

@Injectable()
export class ReportApiService {

  protected reportApiUrl: string = environment.apiBaseEndpoint + environment.reportEndpoint;

  protected header = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'});


  constructor(private http: HttpClient, private fileSaverService: FileSaverService) {
  }

  exportProjectRequirements(id: number, type: string): Observable<any> {
    return this.http.get(this.reportApiUrl + 'requirementsByProject/' + id + '/' + type,
      {headers: this.header, responseType: 'blob' as 'blob', observe: 'response'});
  }

  exportDashboard(type: string): Observable<any> {
    return this.http.get(this.reportApiUrl + 'dashboard/' + type,
      {headers: this.header, responseType: 'blob' as 'blob', observe: 'response'});
  }

  dataURLtoBlob(dataurl) {
    const arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type: mime});
  }

  saveChartImage(data: string, fileName: string): void {
    const imageBlob = this.dataURLtoBlob(data);
    this.fileSaverService.save(imageBlob, fileName);
  }
}
