import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {ApiResponse, Page} from '../models/apiresponse';
import 'rxjs-compat/add/observable/empty';
import 'rxjs-compat/add/observable/of';
import {tap} from 'rxjs/operators';
import {Survey} from '../models/survey';
import {Question, ResponseInstanceNote} from '../models/question';
import {DashboardStatus, PairItem, PairNumberItem} from '../models/dashboard-status';
import {Application} from '../models/application';
import {BatchImport} from '../models/batch-import';
import {Globals} from '../../globals';
import {CustomDataManaged, DataManaged} from '../models/data-managed';
import {Package} from './package';
import {DomainCompliance} from '../models/domain-compliance';
import {Project} from '../models/project';
import {Library} from '../models/library';
import {Requirement, RequirementAttribute} from '../models/requirement';
import {ContextSeverity} from '../models/contextSeverity';
import {Template} from '../models/template';

@Injectable()
export class OteApiService {

  protected templateApiUrl: string = environment.apiBaseEndpoint + environment.templateEndpoint;
  protected surveyApiUrl: string = environment.apiBaseEndpoint + environment.surveyEndpoint;
  protected projectApiUrl: string = environment.apiBaseEndpoint + environment.projectEndpoint;
  protected libraryApiUrl: string = environment.apiBaseEndpoint + environment.libraryEndpoint;
  protected domainApiUrl: string = environment.apiBaseEndpoint + environment.complianceDomainEndpoint;
  protected questionApiUrl: string = environment.apiBaseEndpoint + environment.questionEndpoint;
  protected managedDataApiUrl: string = environment.apiBaseEndpoint + environment.managedDataEndpoint;
  protected customManagedDataApiUrl: string = environment.apiBaseEndpoint + environment.customManagedDataEndpoint;
  protected responseApiUrl: string = environment.apiBaseEndpoint + environment.responseEndpoint;
  protected dashboardApiUrl: string = environment.apiBaseEndpoint + environment.dashboardEndpoint;
  protected applicationApiUrl: string = environment.apiBaseEndpoint + environment.applicationEndpoint;
  protected packageApiUrl: string = environment.apiBaseEndpoint + environment.packageEndpoint;
  protected fileApiUrl: string = environment.apiBaseEndpoint + environment.fileEndpoint;
  protected requirementApiUrl: string = environment.apiBaseEndpoint + environment.requirementEndpoint;
  protected attributeApiUrl: string = environment.apiBaseEndpoint + environment.attributeEndpoint;
  protected sectionApiUrl: string = environment.apiBaseEndpoint + environment.sectionEndpoint;


  protected header = new HttpHeaders({'Content-Type': 'application/json'});
  templateUpdated: EventEmitter<string> = new EventEmitter();
  surveyUpdated: EventEmitter<string> = new EventEmitter();
  surveyCreated: EventEmitter<string> = new EventEmitter();
  questionUpdated: EventEmitter<string> = new EventEmitter();
  questionCreated: EventEmitter<string> = new EventEmitter();
  responseUpdated: EventEmitter<string> = new EventEmitter();
  responseCreated: EventEmitter<string> = new EventEmitter();
  applicationUpdated: EventEmitter<string> = new EventEmitter();
  applicationCreated: EventEmitter<string> = new EventEmitter();
  dataManagedUpdated: EventEmitter<string> = new EventEmitter();
  dataManagedCreated: EventEmitter<string> = new EventEmitter();
  customDataManagedUpdated: EventEmitter<string> = new EventEmitter();
  customDataManagedCreated: EventEmitter<string> = new EventEmitter();
  domainComplianceUpdated: EventEmitter<string> = new EventEmitter();
  domainComplianceCreated: EventEmitter<string> = new EventEmitter();
  packageUpdated: EventEmitter<string> = new EventEmitter();
  packageCreated: EventEmitter<string> = new EventEmitter();
  projectCreated: EventEmitter<string> = new EventEmitter();
  projectUpdated: EventEmitter<string> = new EventEmitter();
  libraryCreated: EventEmitter<string> = new EventEmitter();
  libraryUpdated: EventEmitter<string> = new EventEmitter();
  requirementCreated: EventEmitter<string> = new EventEmitter();
  requirementUpdated: EventEmitter<string> = new EventEmitter();
  oteFileUpdated: EventEmitter<string> = new EventEmitter();
  attributeCreated: EventEmitter<string> = new EventEmitter();
  attributeUpdated: EventEmitter<string> = new EventEmitter();
  projectDataUpdated: EventEmitter<string> = new EventEmitter();
  projectReqUpdated: EventEmitter<string> = new EventEmitter();


  constructor(private http: HttpClient, public globals: Globals) {
  }


  getSurveyList(page: number, size: number, status?: number): Observable<any> {
    let params = new HttpParams().set('page', page.toString()).set('size', size.toString());
    if (status !== undefined && status !== null) {
      params = params.set('status', status.toString());
    }
    return this.http.get<ApiResponse<Page<Survey>>>(this.surveyApiUrl + 'list',
      {params, headers: this.header});
  }

  getCurrentSurvey(): Observable<any> {
    return this.getSurveyList(0, 1, this.globals.SURVEY_MODE_PUBLISHED);
  }

  getQuestionList(surveyId: number): Observable<any> {
    const params = new HttpParams()
      .set('surveyId', surveyId.toString());
    return this.http.get<ApiResponse<Question[]>>(this.questionApiUrl + 'list',
      {params, headers: this.header});
  }

  getResponseInstanceList(surveyId: number): Observable<any> {
    return this.http.get(this.responseApiUrl + 'list/' + surveyId,
      {headers: this.header});
  }

  searchSurvey(term: string, page: number, size: number, green: string, status?: number): Observable<any> {
    let params = new HttpParams().set('page', page.toString())
      .set('size', size.toString()).set('title', term).set('green', green);
    if (status !== null && status !== undefined)
      params = params.set('status', status.toString());
    return this.http.get<ApiResponse<Page<Survey>>>(this.surveyApiUrl + 'list',
      {params, headers: this.header});
  }


  createSurvey(survey: Survey): Observable<any> {
    return this.http.post(
      this.surveyApiUrl + 'create',
      JSON.stringify(survey),
      {headers: this.header},
    ).pipe(
      tap(res => {
        if (res.status.id === 0) {
          this.surveyCreated.emit('survey_created');
        }
      }),
    );
  }

  cloneSurvey(id: number): Observable<any> {
    return this.http.post(
      this.surveyApiUrl + 'clone/' + id,
      null,
      {headers: this.header},
    ).pipe(
      tap(res => {
        if (res.status.id === 0) {
          this.surveyCreated.emit('survey_cloned');
        }
      }),
    );
  }

  updateSurvey(survey: Survey): Observable<any> {
    return this.http.put(this.surveyApiUrl + 'update', JSON.stringify(survey), {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.surveyUpdated.emit('survey_updated');
          }
        }),
      );
  }

  updatePackage(pack: Package): Observable<any> {
    return this.http.put(this.packageApiUrl + 'update', JSON.stringify(pack), {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.packageUpdated.emit('package_updated');
          }
        }),
      );
  }

  getSurveyDetail(surveyInstanceId: number, page: number, size: number,
                  questionText?: string, userFullname?: string): Observable<any> {

    let params = new HttpParams().set('page', page.toString())
      .set('size', size.toString());
    if (questionText)
      params = params.set('questionText', questionText);
    if (userFullname)
      params = params.set('userFullname', userFullname);

    return this.http.get(this.surveyApiUrl + 'report_detail/' + surveyInstanceId,
      {params, headers: this.header});
  }

  public applicationMatching = (value: string, target: Application): boolean => {
    const re = new RegExp(value, 'i');
    return ((target.code.search(re) >= 0) || target.name.search(re) >= 0);
  }

  createOrUpdateQuestion(question: Question): Observable<any> {
    if (question.id) {
      return this.updateQuestion(question);
    } else {
      return this.createQuestion(question);
    }
  }

  createQuestion(question: Question): Observable<any> {
    return this.http.post(
      this.questionApiUrl + 'create',
      JSON.stringify(question),
      {headers: this.header},
    ).pipe(
      tap(res => {
        if (res.status.id === 0) {
          this.questionCreated.emit('question_created');
        }
      }),
    );
  }

  createOrUpdateResponseInstanceNote(responses: ResponseInstanceNote, update: boolean, recursive: boolean): Observable<any> {
    if (update) {
      return this.updateResponseInstanceNote(responses, recursive);
    } else {
      return this.createResponseInstanceNote(responses, recursive);
    }
  }

  createResponseInstanceNote(responses: ResponseInstanceNote, recursive: boolean): Observable<any> {
    return this.http.post(
      this.responseApiUrl + 'create?recursive=' + recursive,
      JSON.stringify(responses),
      {headers: this.header},
    ).pipe(
      tap(res => {
        if (res.status.id === 0) {
          this.responseCreated.emit('response_instance_created');
        }
      }),
    );
  }

  updateResponseInstanceNote(responses: ResponseInstanceNote, recursive: boolean): Observable<any> {
    return this.http.put(this.responseApiUrl + 'update', JSON.stringify(responses), {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.responseUpdated.emit('response_instance_updated');
          }
        }),
      );
  }

  updateProgressSurveyInstance(idSurvey: number, progress: number | string): Observable<any> {
    return this.http.put(this.surveyApiUrl + 'update_progress?surveyInstanceId=' + idSurvey + '&progress=' + progress,
      null, {headers: this.header});
  }

  updateQuestion(question: Question): Observable<any> {
    // console.log(JSON.stringify(question));
    return this.http.put(this.questionApiUrl + 'update', JSON.stringify(question), {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.questionUpdated.emit('question_updated');
          }
        }),
      );
  }

  updateQuestionPosition(question: Question): Observable<any> {
    const params = new HttpParams()
      .set('idQuestion', question.id.toString())
      .set('position', question.position.toString())
    return this.http.put(this.questionApiUrl + 'change_position', null, {params: params, headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.questionUpdated.emit('question_position_updated');
          }
        }),
      );
  }

  exportSurvey(id: number): Observable<any> {
    return this.http.get(this.surveyApiUrl + 'export/' + id,
      {headers: this.header, responseType: 'json', observe: 'response'});
  }

  importSurvey(file: File): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    const req = new HttpRequest('POST', this.surveyApiUrl + 'import', formdata, {
        reportProgress: true,
      },
    );
    return this.http.request(req);
  }

  getSurvey(id: any): Observable<any> {
    return this.http.get<ApiResponse<Survey>>(this.surveyApiUrl + 'retrieve/' + id, {headers: this.header});
  }

  deleteQuestion(id: number): Observable<any> {
    return this.http.delete<ApiResponse<Question>>(this.questionApiUrl + 'delete/' + id, {headers: this.header});
  }

  checkCompleteSurvey(id: any): Observable<any> {
    return this.http.post(this.surveyApiUrl + 'check_complete?surveyInstanceId=' + id, {headers: this.header});
  }


  getDashboardStatus(): Observable<any> {
    return this.http.get<ApiResponse<DashboardStatus>>(this.dashboardApiUrl + 'status', {headers: this.header});
  }

  getDashboardStatusByParams(pair: PairItem): Observable<any> {
    return this.http.post<ApiResponse<DashboardStatus>>(
      this.dashboardApiUrl + 'status_by_param', JSON.stringify(pair), {headers: this.header});
  }

  uploadImportFile(file: File): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    const req = new HttpRequest('POST', this.fileApiUrl + 'upload', formdata, {
        reportProgress: true,
        responseType: 'json',
      }
    );
    return this.http.request(req);
  }

  importApplications(importJob: BatchImport): Observable<any> {
    return this.http.post(
      this.applicationApiUrl + 'import',
      JSON.stringify(importJob),
      {headers: this.header}
    );
  }

  importPackages(importJob: BatchImport): Observable<any> {
    return this.http.post(
      this.packageApiUrl + 'import',
      JSON.stringify(importJob),
      {headers: this.header}
    );
  }

  exportApplication(): Observable<any> {
    return this.http.get(this.applicationApiUrl + 'export',
      {headers: this.header, responseType: 'blob' as 'blob', observe: 'response'});
  }

  getApplicationList(page: number, size: number): Observable<any> {
    const params = new HttpParams().set('page', page.toString()).set('size', size.toString());
    return this.http.get<ApiResponse<Page<Application>>>(this.applicationApiUrl + 'list',
      {params, headers: this.header});
  }

  searchApplication(term: string, page: number, size: number, status?: number, nameOrCode?: boolean): Observable<any> {
    let params = new HttpParams().set('page', page.toString()).set('size', size.toString());
    params = (nameOrCode) ? params.set('nameOrCode', term) : params.set('nameOrCode', term);
    if (status !== null && status !== undefined) {
      params = params.set('status', status.toString());
    }
    return this.http.get<ApiResponse<Page<Application>>>(this.applicationApiUrl + 'list',
      {params, headers: this.header});
  }

  searchApplicationPackage(term: string, page: number, size: number, status?: number, nameOrCode?: boolean, applicationIds?: number[]): Observable<any> {
    let params = new HttpParams().set('page', page.toString()).set('size', size.toString());
    params = (nameOrCode) ? params.set('nameOrCode', term) : params.set('nameOrCode', term);
    if (status !== null && status !== undefined) {
      params = params.set('status', status.toString());
    }
    if (applicationIds !== null && applicationIds !== undefined) {
      params = params.set('applicationIds', applicationIds.toString());
    }
    return this.http.get<ApiResponse<Page<Package>>>(this.packageApiUrl + 'list',
      {params, headers: this.header});
  }

  public applicationPackageMatching = (value: string, target: Application): boolean => {
    const re = new RegExp(value, 'i');
    return ((target.code.search(re) >= 0) || target.name.search(re) >= 0);
  }

  updateApplication(application: Application): Observable<any> {
    return this.http.put(this.applicationApiUrl + 'update', JSON.stringify(application), {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id == 0) {
            this.applicationUpdated.emit('application_updated');
          }
        })
      );
  }

  createApplication(application: Application): Observable<any> {
    return this.http.post(
      this.applicationApiUrl + 'create',
      JSON.stringify(application),
      {headers: this.header}
    ).pipe(
      tap(res => {
        if (res.status.id === 0) {
          this.applicationCreated.emit('application_created');
        }
      }),
    );
  }

  createPackage(pack: Package): Observable<any> {
    return this.http.post(
      this.packageApiUrl + 'create',
      JSON.stringify(pack),
      {headers: this.header}
    ).pipe(
      tap(res => {
        if (res.status.id === 0) {
          this.packageCreated.emit('package_created');
        }
      }),
    );

  }

  createDataManaged(data: DataManaged): Observable<any> {
    return this.http.post(
      this.managedDataApiUrl + 'create',
      JSON.stringify(data),
      {headers: this.header},
    ).pipe(
      tap(res => {
        if (res.status.id === 0) {
          this.dataManagedCreated.emit('data_created');
        }
      }),
    );
  }

  updateDataManaged(data: DataManaged): Observable<any> {
    return this.http.put(this.managedDataApiUrl + 'update', JSON.stringify(data), {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.dataManagedUpdated.emit('data_updated');
          }
        }),
      );
  }

  createCustomDataManaged(data: CustomDataManaged): Observable<any> {
    return this.http.post(
      this.customManagedDataApiUrl + 'create',
      JSON.stringify(data),
      {headers: this.header},
    ).pipe(
      tap(res => {
        if (res.status.id === 0) {
          this.customDataManagedCreated.emit('custom_data_created');
        }
      }),
    );
  }

  updateCustomDataManaged(data: CustomDataManaged): Observable<any> {
    return this.http.put(this.customManagedDataApiUrl + 'update', JSON.stringify(data), {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.customDataManagedUpdated.emit('custom_data_updated');
          }
        }),
      );
  }

  getCustomDataManaged(id: number): Observable<any> {
    return this.http.get<ApiResponse<CustomDataManaged>>(this.customManagedDataApiUrl + 'retrieve/' + id, {headers: this.header});
  }

  deleteCustomDataManaged(id: number): Observable<any> {
    return this.http.delete(this.customManagedDataApiUrl + 'delete?id=' + id, {headers: this.header, observe: 'body'})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.customDataManagedUpdated.emit('custom_data_deleted');
          }
        }),
      );
  }


  assignDataManagedToDomain(idManagedData: number, idComplianceDomain: number): Observable<any> {
    return this.http.post(this.managedDataApiUrl + 'assign?idManagedData=' + idManagedData + '&idComplianceDomain=' + idComplianceDomain,
      null, {headers: this.header});
  }

  unassignDataManagedToDomain(idManagedData: number, idComplianceDomain: number): Observable<any> {
    return this.http.post(this.managedDataApiUrl + 'unassign?idManagedData=' + idManagedData + '&idComplianceDomain=' + idComplianceDomain,
      null, {headers: this.header});
  }

  getDataManagedComplianceDomainMatrix(): Observable<any> {
    return this.http.get(this.managedDataApiUrl + 'matrix_manageddata_compliancedomain',
      {headers: this.header});
  }

  getRequirementComplianceDomainMatrix(page: number, size: number): Observable<any> {
    return this.http.get(this.requirementApiUrl + 'matrix_requirement_compliancedomain?page=' + page + '&size=' + size,
      {headers: this.header});
  }

  updateAssignmentDataManagedToDomain(checked: boolean | any, idManagedData: number, idComplianceDomain: number): Observable<any> {
    if (checked)
      return this.assignDataManagedToDomain(idManagedData, idComplianceDomain);
    else
      return this.unassignDataManagedToDomain(idManagedData, idComplianceDomain);

  }

  getDataManagedList(page: number, size: number, name?: string): Observable<any> {
    let params = new HttpParams().set('page', page.toString()).set('size', size.toString());
    if (name !== null && name !== undefined) {
      params = params.set('name', name);
    }
    return this.http.get<ApiResponse<DomainCompliance[]>>(this.managedDataApiUrl + 'list',
      {params, headers: this.header});
  }

  exportApplicationPackages(): Observable<any> {
    return this.http.get(this.packageApiUrl + 'export',
      {headers: this.header, responseType: 'blob' as 'blob', observe: 'response'});
  }

  getDomainComplianceList(page: number, size: number, name?: string): Observable<any> {
    let params = new HttpParams().set('page', page.toString()).set('size', size.toString());
    if (name !== null && name !== undefined) {
      params = params.set('name', name);
    }
    return this.http.get<ApiResponse<DomainCompliance[]>>(this.domainApiUrl + 'list',
      {params, headers: this.header});
  }

  createDomainCompliance(domain: DomainCompliance): Observable<any> {
    return this.http.post(
      this.domainApiUrl + 'create',
      JSON.stringify(domain),
      {headers: this.header},
    ).pipe(
      tap(res => {
        if (res.status.id === 0) {
          this.domainComplianceCreated.emit('domain_created');
        }
      }),
    );
  }

  updateDomainCompliance(domain: DomainCompliance): Observable<any> {
    return this.http.put(this.domainApiUrl + 'update', JSON.stringify(domain), {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.domainComplianceUpdated.emit('domain_updated');
          }
        }),
      );
  }

  createProject(project: Project, notify?: boolean, confirm?: boolean): Observable<any> {
    let urlParams = notify ? '?notify=true' : '';
    if (confirm) {
      if (urlParams.length > 0) {
        urlParams += '&confirm=true';
      } else {
        urlParams = '?confirm=true';
      }
    }

    return this.http.post(
      this.projectApiUrl + 'create' + urlParams,
      JSON.stringify(project),
      {headers: this.header},
    ).pipe(
      tap(res => {
        if (res.status.id === 0) {
          this.projectCreated.emit('project_created');
        }
      }),
    );
  }

  updateProjectStatus(idProject: number, status: number): Observable<any> {
    return this.http.put(this.projectApiUrl + 'update_project_status?idProject=' + idProject + '&status=' + status,
      null, {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.projectUpdated.emit('project_updated');
          }
        }),
      );
  }

  getProject(id: number): Observable<any> {
    return this.http.get<ApiResponse<Project>>(this.projectApiUrl + 'retrieve/' + id, {headers: this.header});
  }

  computeResultProject(id: number): Observable<any> {
    return this.http.post<ApiResponse<any>>(this.projectApiUrl + 'compute_result?idProject=' + id, {headers: this.header});
  }

  updateProject(project: Project, notify?: boolean, confirm?: boolean): Observable<any> {
    let urlParams = notify ? '?notify=true' : '';
    if (confirm) {
      if (urlParams.length > 0) {
        urlParams += '&confirm=true';
      } else {
        urlParams = '?confirm=true';
      }
    }
    return this.http.put(this.projectApiUrl + 'update' + urlParams, JSON.stringify(project), {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.projectUpdated.emit('project_updated');
          }
        }),
      );
  }

  deleteDataManaged(id: number): Observable<any> {
    return this.http.delete(this.managedDataApiUrl + 'delete?id=' + id, {headers: this.header, observe: 'body'})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.dataManagedUpdated.emit('data_deleted');
          }
        }),
      );
  }

  deleteComplianceDomain(id: number): Observable<any> {
    return this.http.delete(this.domainApiUrl + 'delete?id=' + id, {headers: this.header, observe: 'body'})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.domainComplianceUpdated.emit('domain_deleted');
          }
        }),
      );
  }


  createLibrary(library: Library): Observable<any> {
    return this.http.post(
      this.libraryApiUrl + 'create',
      JSON.stringify(library),
      {headers: this.header}
    ).pipe(
      tap(res => {
        if (res.status.id === 0) {
          this.libraryCreated.emit('library_created');
        }
      }),
    );
  }

  cloneLibrary(versionNumber: string): Observable<any> {
    return this.http.post(
      this.libraryApiUrl + 'clone?versionNumber=' + versionNumber,
      null,
      {headers: this.header}
    ).pipe(
      tap(res => {
        if (res.status.id === 0) {
          this.libraryCreated.emit('library_created');
        }
      }),
    );
  }

  updateLibrary(library: Library): Observable<any> {
    return this.http.put(this.libraryApiUrl + 'update', JSON.stringify(library), {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.libraryUpdated.emit('library_updated');
          }
        }),
      );
  }

  deleteLibrary(id: number): Observable<any> {
    return this.http.delete(this.libraryApiUrl + 'delete?id=' + id, {headers: this.header, observe: 'body'})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.libraryUpdated.emit('library_deleted');
          }
        }),
      );
  }

  getLibraryList(page: number, size: number, status?: number): Observable<any> {
    let params = new HttpParams().set('page', page.toString()).set('size', size.toString());
    if (status !== undefined && status !== null) {
      params = params.set('status', status.toString());
    }
    return this.http.get<ApiResponse<Page<Library>>>(this.libraryApiUrl + 'list',
      {params, headers: this.header});
  }

  getCurrentLibrary(): Observable<any> {
    return this.getLibraryList(0, 1, this.globals.SURVEY_MODE_PUBLISHED);
  }

  getRequirement(id: number): Observable<any> {
    return this.http.get<ApiResponse<Requirement>>(this.requirementApiUrl + 'retrieve/' + id, {headers: this.header});
  }

  createRequirement(requirement: Requirement): Observable<any> {
    return this.http.post(
      this.requirementApiUrl + 'create',
      JSON.stringify(requirement),
      {headers: this.header}
    ).pipe(
      tap(res => {
        if (res.status.id === 0) {
          this.requirementCreated.emit('requirement_created');
        }
      }),
    );
  }

  updateRequirement(requirement: Requirement): Observable<any> {
    return this.http.put(this.requirementApiUrl + 'update', JSON.stringify(requirement), {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.requirementUpdated.emit('requirement_updated');
          }
        }),
      );
  }

  assignRequirementToQuestion(idRequirement: number, idQuestion: number): Observable<any> {
    return this.http.post(this.requirementApiUrl + 'assign_question?idRequirement=' + idRequirement + '&idQuestion=' + idQuestion,
      null, {headers: this.header});
  }

  unassignRequirementToQuestion(idRequirement: number, idQuestion: number): Observable<any> {
    return this.http.post(this.requirementApiUrl + 'unassign_question?idRequirement=' + idRequirement + '&idQuestion=' + idQuestion,
      null, {headers: this.header});
  }

  assignRequirementToComplianceDomain(idRequirement: number, idComplianceDomain: number, norm: string): Observable<any> {
    return this.http.post(this.requirementApiUrl + 'assign_compliancedomain',
      {idRequirement: idRequirement, idComplianceDomain: idComplianceDomain, norm: norm}, {headers: this.header});
  }

  unassignRequirementToComplianceDomain(idRequirement: number, idComplianceDomain: number): Observable<any> {
    return this.http.post(this.requirementApiUrl + 'unassign_compliancedomain?idRequirement=' + idRequirement + '&idComplianceDomain=' + idComplianceDomain,
      null, {headers: this.header});
  }

  updateAssignmentRequirementToDomain(checked: boolean, idRequirement: number, idComplianceDomain: number, norm: string = null): Observable<any> {
    if (checked)
      return this.assignRequirementToComplianceDomain(idRequirement, idComplianceDomain, norm);
    else
      return this.unassignRequirementToComplianceDomain(idRequirement, idComplianceDomain);
  }

  updateAssignmentRequirementToQuestion(checked: boolean | any, idRequirement: number, idQuestion: number): Observable<any> {
    if (checked)
      return this.assignRequirementToQuestion(idRequirement, idQuestion);
    else
      return this.unassignRequirementToQuestion(idRequirement, idQuestion);
  }

  getRequirementExclusionList(idRequirement: number): Observable<any> {
    return this.http.get<ApiResponse<Requirement>>(
      this.requirementApiUrl + 'get_exclusion?idRequirement=' + idRequirement, {headers: this.header});
  }

  getRequirementSettings(idRequirement: number): Observable<any> {
    return this.http.get<ApiResponse<Requirement>>(
      this.requirementApiUrl + 'get_requirement_settings?idRequirement=' + idRequirement, {headers: this.header});
  }

  setRequirementSetting(idRequirement: number, idQuestion: number, value: string): Observable<any> {
    return this.http.put(this.requirementApiUrl + 'set_requirement_setting?idRequirement='
      + idRequirement + '&idQuestion=' + idQuestion + '&value=' + value,
      null, {headers: this.header});
  }


  importRequirements(importJob: BatchImport): Observable<any> {
    return this.http.post(
      this.requirementApiUrl + 'import',
      JSON.stringify(importJob),
      {headers: this.header}
    );
  }


  getLayers() {
    return [];
  }

  exportLibrary(id: number): Observable<any> {
    return this.http.get(this.requirementApiUrl + 'export?idLibrary=' + id,
      {headers: this.header, responseType: 'blob' as 'blob', observe: 'response'});
  }

  retrieveOteFile(idProject: number): Observable<any> {
    return this.http.get(this.projectApiUrl + 'retrieve_ote_file?idProject=' + idProject,
      {headers: this.header, responseType: 'blob' as 'blob', observe: 'response'});
  }

  infoOteFile(idProject: number): Observable<any> {
    return this.http.get(this.projectApiUrl + 'info_ote_file?idProject=' + idProject,
      {headers: this.header});
  }

  deleteOteFile(idProject: number): Observable<any> {
    return this.http.delete(this.projectApiUrl + 'delete_ote_file?idProject=' + idProject,
      {headers: this.header, observe: 'body'})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.oteFileUpdated.emit('oteFileDeleted');
          }
        }),
      );
  }

  addOteFile(file: File, idProject: number): Observable<HttpEvent<{}>> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    const req = new HttpRequest('POST', this.projectApiUrl + 'add_ote_file?idProject=' + idProject, formdata, {
        reportProgress: true,
      },
    );
    return this.http.request(req);
  }

  retrieveProjectRequirements(idProject: number): Observable<any> {
    return this.http.get(this.projectApiUrl + 'retrieve_requirement?idProject=' + idProject,
      {headers: this.header});
  }

  retrieveProjectDataManaged(idProject: number): Observable<any> {
    return this.http.get(this.projectApiUrl + 'retrieve_managed_data?idProject=' + idProject,
      {headers: this.header});
  }

  retrieveProjectComplianceSettings(idProject: number): Observable<any> {
    return this.http.get(this.projectApiUrl + 'retrieve_compliance_settings?idProject=' + idProject,
      {headers: this.header});
  }

  getQuestionSectionList(surveyId: number): Observable<any> {
    return this.http.get(this.sectionApiUrl + 'list?surveyId=' + surveyId,
      {headers: this.header});
  }

  deleteProjectDataManaged(idProject: number, idManagedData: number): Observable<any> {
    return this.http.delete(this.projectApiUrl + 'delete_managed_data?idProject=' + idProject + '&idManagedData=' + idManagedData,
      {headers: this.header, observe: 'body'})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.projectUpdated.emit('project_data_updated');
          }
        }),
      );
  }

  addProjectDataManaged(idProject: number, idManagedData: number): Observable<any> {
    return this.http.post(this.projectApiUrl + 'add_managed_data?idProject=' + idProject + '&idManagedData=' + idManagedData,
      null, {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.projectUpdated.emit('project_data_updated');
          }
        }),
      );
  }


  deleteProjectRequirement(idProject: number, idRequirement: number): Observable<any> {
    return this.http.delete(this.projectApiUrl + 'delete_requirement?idProject=' + idProject + '&idRequirement=' + idRequirement,
      {headers: this.header, observe: 'body'})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.projectUpdated.emit('project_data_updated');
          }
        }),
      );
  }

  addProjectRequirement(idProject: number, idRequirement: number): Observable<any> {
    return this.http.post(this.projectApiUrl + 'add_requirement?idProject=' + idProject + '&idRequirement=' + idRequirement,
      null, {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.projectUpdated.emit('project_data_updated');
          }
        }),
      );
  }

  createAttribute(attribute: RequirementAttribute): Observable<any> {
    return this.http.post(this.attributeApiUrl + 'create',
      JSON.stringify(attribute), {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.attributeCreated.emit('attribute_created');
          }
        }),
      );
  }

  updateAttribute(attribute: RequirementAttribute): Observable<any> {
    return this.http.put(this.attributeApiUrl + 'update',
      JSON.stringify(attribute), {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.attributeUpdated.emit('attribute_updated');
          }
        }),
      );
  }

  deleteAttribute(idAttribute: number): Observable<any> {
    return this.http.delete(this.attributeApiUrl + 'delete?id=' + idAttribute,
      {headers: this.header, observe: 'body'})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.projectUpdated.emit('attribute_deleted');
          }
        }),
      );
  }

  searchAttribute(term: string, page: number, size: number, status?: number): Observable<any> {
    let params = new HttpParams().set('page', page.toString())
      .set('size', size.toString()).set('title', term);
    if (status !== null && status !== undefined)
      params = params.set('status', status.toString());
    return this.http.get<ApiResponse<Page<RequirementAttribute>>>(this.attributeApiUrl + 'list',
      {params, headers: this.header});
  }

  updateProjectComplianceDomainSetting(checked: boolean, idProject: number, idManagedData: number, idDomain: number): Observable<any> {
    if (checked) {
      return this.addProjectComplianceDomainSetting(idProject, idManagedData, idDomain);
    } else {
      return this.deleteProjectComplianceDomainSetting(idProject, idManagedData, idDomain);
    }
  }

  addProjectComplianceDomainSetting(idProject: number, idManagedData: number, idDomain: number): Observable<any> {
    return this.http.post(
      this.projectApiUrl + 'add_compliance_domain?idProject=' + idProject + '&idManagedData=' + idManagedData + '&idComplianceDomain=' + idDomain,
      null,
      {headers: this.header}
    );
  }

  updateMatrixComplianceDomainManagedData(activeManagedDataComplianceDomains: PairNumberItem[], idProject: number): Observable<any> {
    return this.http.post(
      this.projectApiUrl + 'update_matrix_manageddata_compliancedomains',
      {activeManagedDataComplianceDomains: activeManagedDataComplianceDomains, idProject: idProject},
      {headers: this.header}
    );
  }

  updateMatrixRequirementComplianceDomain(activeRequirementsComplianceDomains: PairNumberItem[], requirementsIds: number[]): Observable<any> {
    return this.http.post(
      this.requirementApiUrl + 'update_matrix_requirements_compliancedomains',
      {activeRequirementsComplianceDomains: activeRequirementsComplianceDomains, requirementsIds: requirementsIds},
      {headers: this.header}
    );
  }

  deleteProjectComplianceDomainSetting(idProject: number, idManagedData: number, idDomain: number): Observable<any> {
    return this.http.delete(
      this.projectApiUrl + 'delete_compliance_domain?idProject=' + idProject + '&idManagedData=' + idManagedData + '&idComplianceDomain=' + idDomain,
      {headers: this.header, observe: 'body'});
  }

  updateOteStatus(idProject: number, oteStatus: number): Observable<any> {
    return this.http.put(this.projectApiUrl + 'update_project_status_ote?idProject=' + idProject + '&status=' + oteStatus,
      null, {headers: this.header})
  }

  saveContextSeverity(contextObj: ContextSeverity): Observable<any> {
    return this.http.post(
      this.projectApiUrl + 'update_requirement_info',
      JSON.stringify(contextObj),
      {headers: this.header}
    ).pipe(
      tap(res => {
        if (res.status.id === 0) {
          this.projectReqUpdated.emit('requirement_fileds_updated');
        }
      }),
    );
  }

  listTemplate(page: number, size: number): Observable<any> {
    const params = new HttpParams().set('page', page.toString()).set('size', size.toString());
    return this.http.get<ApiResponse<Page<Template>>>(this.templateApiUrl + 'list',
      {params, headers: this.header});
  }

  retrieveTemplate(id: number): Observable<any> {
    return this.http.get<ApiResponse<any>>(this.templateApiUrl + 'retrieve/' + id,
      {headers: this.header});
  }

  updateTemplate(template: Template): Observable<any> {
    return this.http.put(this.templateApiUrl + 'update', JSON.stringify(template), {headers: this.header})
      .pipe(
        tap(res => {
          if (res.status.id === 0) {
            this.templateUpdated.emit('template_updated');
          }
        }),
      );
  }
}
