import {Component} from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
  <span class="created-by layout-footer"><img style="max-height:40px;" src="/assets/images/logo_ote.png"> - R M T </span>
                                       <!-- <img style="max-height:40px;" src="/assets/images/logo_ote_POSTE.png"> - OTE - <img style="max-height:20px;" src="/assets/images/logoposte.png"></span>-->

  `,
})
export class FooterComponent {
}
