import {Component, Input, OnInit} from '@angular/core';
import {NbMenuService, NbSidebarService} from '@nebular/theme';
import {NbAuthJWTToken, NbAuthService} from '../../../auth/services';
import {UserApiService} from '../../../@core/data/user-api.service';
import {NbAclService} from '../../../security';
import {ApiResponse, Page} from '../../../@core/models/apiresponse';
import {Company} from '../../../@core/models/company';
import {ToasterBuilder} from '../../../@core/utils/toaster-builder';
import {ToasterConfig, ToasterService} from 'angular2-toaster';
import {ToasterEmitterService, ToasterEvent} from '../../../@core/utils/toaster-emitter-service';
import 'style-loader!angular2-toaster/toaster.css';
import {Globals} from '../../../globals';
import {OteApiService} from '../../../@core/data/ote-api.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {


  @Input() position = 'normal';
  user = {fullname: '', picture: 'assets/images/nick.png', companyId: '', id: null};

  userMenu = [
    // {title: 'Profilo utente', link: '/user/profile'},
    {title: 'Cambia Password', link: '/auth/reset-password'},
    {title: 'Logout', link: '/auth/logout'},
  ];


  userMenuNotifiche = [{title: 'Profilo'}];
  companies: Company[];
  selectedCompanyId: any;
  private strategyName: string;
  showCompaniesList = false;
  toasterBuilder: ToasterBuilder;
  config: ToasterConfig;
  isFullscreen = false;
  loadCompaniesFromServer: boolean = false;

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              public aclService: NbAclService,
              private globals: Globals,
              private toasterService: ToasterService,
              private toasterEmitterService: ToasterEmitterService,
              private surveyService: OteApiService,
              private authService: NbAuthService,
              private userService: UserApiService) {
    this.toasterBuilder = new ToasterBuilder();
    this.config = this.toasterBuilder.configTopCenter;

    this.toasterEmitterService.toasterEmitter.subscribe((toaster: ToasterEvent) => {
      if (toaster.special && toaster.special === 'co') {
        this.loadCompanies();
      }
      this.toasterService.popAsync(toaster.type, toaster.title, toaster.message);
    });

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user.fullname = token.getPayload().username;
          this.user.companyId = token.getPayload().id_company;
          this.selectedCompanyId = this.user.companyId;
          this.strategyName = token.getOwnerStrategyName();
          this.user.id = token.getPayload().id;
        }
      });


    this.userService.companyCreated.subscribe(value => {
      this.loadCompanies();
    });

    this.userService.companyUpdated.subscribe(value => {
      this.loadCompanies();
    });

    if(this.loadCompaniesFromServer) {
      if (this.aclService.canCurrent('ALL_ALL')) {
        this.loadCompanies();
      } else {
        this.userService.getCompany(this.user.companyId).subscribe((res: ApiResponse<Company>) => {
            this.companies = [res.data];
            this.showCompaniesList = true;
          },
          err => {
            this.showCompaniesList = false;
          }
        );
      }
    }
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');
    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  loadCompanies() {
    this.userService.getCompanyList(0, 100, this.globals.COMPANY_STATUS_ACTIVE)
      .subscribe((res: ApiResponse<Page<Company>>) => {
          this.companies = res.data.content;
          this.showCompaniesList = true;
        },
        err => {
          this.showCompaniesList = false;
        }, () => {
        }
      );
  }
  elem;
  ngOnInit(): void {
  }
  toggleFullscreen() {
    // Trigger fullscreen
    const document: any = window.document;
    this.elem = document.documentElement;
    const isFullscreen = document.documentElement as HTMLElement & {
      mozRequestFullScreen(): Promise<void>;
      webkitRequestFullscreen(): Promise<void>;
      msRequestFullscreen(): Promise<void>;
    };

    if (
        document.fullscreenEnabled ||
        document.webkitFullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.msFullscreenEnabled
    ) {
      if (!this.isFullscreen) {
        if (this.elem.requestFullscreen) {
          this.elem.requestFullscreen();
          this.isFullscreen = true;
          return;
        } else if (this.elem.webkitRequestFullscreen) {
          this.elem.webkitRequestFullscreen();
          this.isFullscreen = true;
          return;
        } else if (this.elem.mozRequestFullScreen) {
          this.elem.mozRequestFullScreen();
          this.isFullscreen = true;
          return;
        } else if (this.elem.msRequestFullscreen) {
          this.elem.msRequestFullscreen();
          this.isFullscreen = true;
          return;
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
          this.isFullscreen = false;
          return;
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
          this.isFullscreen = false;
          return;
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
          this.isFullscreen = false;
          return;
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
          this.isFullscreen = false;
          return;
        }
      }
      ;
    }
  }

  onCurrentCompanyChange(value: any) {
    this.authService.refreshToken(this.strategyName, this.selectedCompanyId).subscribe(res => {
    });
  }

}
