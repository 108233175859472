import {Inject, Injectable, Injector} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {NbAuthToken} from '../token/token';
import {NbAuthService} from '../auth.service';
import {NB_AUTH_TOKEN_INTERCEPTOR_FILTER} from '../../auth.options';
import {ToasterEmitterService} from '../../../@core/utils/toaster-emitter-service';
import {environment} from '../../../../environments/environment';

@Injectable()
export class NbAuthJWTInterceptor implements HttpInterceptor {

  constructor(private injector: Injector,
              private errorsEmitter: ToasterEmitterService,
              @Inject(NB_AUTH_TOKEN_INTERCEPTOR_FILTER) protected filter) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // do not intercept request whose urls are filtered by the injected filter
    if (!this.filter(req)) {
      return this.authService.isAuthenticatedOrRefresh(req).pipe(
        switchMap(authenticated => {
          if (authenticated) {
            return this.authService.getToken().pipe(
              switchMap((token: NbAuthToken) => {
                const JWT = `${token.getValue()}`;
                const reqMod = req.clone({
                  headers: req.headers.set(environment.authHeader, JWT),
                });
                 return next.handle(reqMod).pipe(
                  tap((res: HttpEvent<any>) => {
                      if (res instanceof HttpResponse
                        && res.body['status'] !== undefined && res.body['status'].id > 0) {
                        this.errorsEmitter.emitHttpTranslated(res);
                      }
                    },
                    (err: any) => {
                      if (err instanceof HttpErrorResponse) {
                        this.errorsEmitter.emitHttpError(err);
                      }
                    }));
              }));
          } else {
            // Request is sent to server without authentication so that the client code
            // receives the 401/403 error and can act as desired ('session expired', redirect to login, aso)
            return next.handle(req);
          }
        }));
    } else {
      return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          this.errorsEmitter.emitHttpError(err);
        }
      }));
    }
  }

  protected get authService(): NbAuthService {
    return this.injector.get(NbAuthService);
  }

}
